






















































import {
  Component,
  Vue,
} from 'vue-property-decorator';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import {
  getComponent, getConfigEnv, getView,
} from '@/utils/helpers';
import metaDataModule from '@/store/modules/metaDataModule';
import tasqsListModule from '@/store/modules/tasqsListModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    ActionBuilderForm: () => getView('ActionBuilderForm'),
    FormFilterPopup: () => getView('FormFilterPopup'),
    DatalistDetailPopup: () => getView('DataListDetailPopup'),
    AgGridVue,

  },
})
export default class DataListBuilder extends Vue {
   context: any = null;

   searchQuery = '';

   deleteDatalistPopup = false;

   loadingDelete = false;

   datalistID = ''

   dataLoading = true;

    datalist: any = {
      id: null,
      title: '',
      lastEditor: '',
      lastEditDate: '',
      formCount: 0,
      required: false,
      newVal: '',
      options: [

      ],
      isActive: true,
      isCustom: true,
    }

     defaultDatalist: any = {
       id: null,
       title: '',
       lastEditor: '',
       lastEditDate: '',
       required: false,
       newVal: '',
       formCount: 0,
       options: [

       ],
       isActive: true,
       isCustom: true,
     }

   activeTasqTab = 'Submitted Today'

   showDatalistDetailPopup = false;

     columnDefs: any = [

     ];

     //  submittedData =[

     //    {
     //      formName: 'Plunger Replacement',
     //      wellName: 'Alan 102H',
     //      submittedAt: '8/17/22',
     //    },
     //    {
     //      formName: 'Site Inspection',
     //      wellName: 'Alan 103H',
     //      submittedAt: '8/17/22',
     //    },
     //    {
     //      formName: 'Setpoint change',
     //      wellName: 'Alan 105H',
     //      submittedAt: '8/17/22',
     //    },
     //  ]

     get submittedData() {
       if (this.activeTasqTab === 'Submitted Today') {
         return tasqsListModule.submittedTasqs.map((tasq) => tasq.formsResponse.map((form) => ({ wellName: tasq.wellName, formName: form.title, submittedAt: tasq.lastActionTime ? new Date(tasq.lastActionTime).toDateString() : new Date().toDateString() }))).flat();
       }

       return tasqsListModule.pendingTasqs.map((tasq) => tasq.formsResponse.map((form) => ({ wellName: tasq.wellName || tasq.well, formName: form.title, submittedAt: new Date().toDateString() }))).flat();

       // console.log(data)
     }

     showFormFilter = false;

     showFormDetail = false;

     defaultColDef = {
       initialWidth: 350,
       sortable: true,
       filter: true,
       editable: false,
       autoHeight: true,
       cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
       resizable: true,
     };

    rowData:any = []

    requiredDataList: any = [];

    cellClick(e) {
      // console.log(e);
    }

      getRowNodeId: any = null;

  gridApi: any = null;

  gridColumnApi: any = null;

  async closeDatalistPopup() {
    this.showDatalistDetailPopup = false;
    this.datalist = this.defaultDatalist;
  }

  async onGridReady(params) {
    this.gridApi = params.api;
    // this.rowData = [];
    this.columnDefs = [];
    this.gridColumnApi = params.columnApi;
    const columns = [];
    this.gridApi.hideOverlay();

    this.columnDefs = [
      {
        field: 'formName',
        cellClass: 'noborder',
        cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
        sortable: true,
        filter: true,
        width: 250,
        colId: 'title',
        headerName: 'Form Name',
      },
      {
        field: 'wellName',
        editable: false,
        width: 350,
        colId: 'updatedBy',
        cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
        cellClass: 'noborder',
        headerName: 'Asset',
      },
      {
        field: 'submittedAt',
        editable: false,
        width: 190,
        colId: 'updatedAt',
        cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
        cellClass: 'noborder',
        headerName: 'Submitted Date',
      },
    ];
  }

  async created() {

  }
}

